@font-face {
    font-family: "Lexend";
    src: local("Lexend Regular"), local("Lexend-Regular"), url("~@assets/fonts/Lexend-Regular.woff2") format("woff2"),
        url("~@assets/fonts/Lexend-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lexend";
    src: local("Lexend Light"), local("Lexend-Light"), url("~@assets/fonts/Lexend-Light.woff2") format("woff2"),
        url("~@assets/fonts/Lexend-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lexend";
    src: local("Lexend SemiBold"), local("Lexend-SemiBold"), url("~@assets/fonts/Lexend-SemiBold.woff2") format("woff2"),
        url("~@assets/fonts/Lexend-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
