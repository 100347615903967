@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-list {
  list-style: none;

  &--columns {
    &-1 {
      column-count: 1;
    }

    &-2 {
      column-count: 2;
    }
  }

  &--no-padding {
    padding: 0;
  }
}
