@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-todo-finished {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: var(--ac-color-feedback-succes);
  fill: var(--ac-color-feedback-succes);

  &-icon {
    margin-right: 0.5rem;
  }
}
