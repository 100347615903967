@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.user-profile-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media #{$breakpoint-xl-up} {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    &__col-right {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
}