@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-product-item {
  display: flex;
  flex-direction: column;
  min-height: 3.5rem;
  justify-content: center;
  transition: background-color 0.2s ease, padding 0.2s ease, margin 0.2s ease;
  border-radius: var(--ac-pixel-size-3);
  cursor: pointer;

  &:not(:first-of-type) {
    border-top: 1px solid var(--ac-color-neutral-200);
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--ac-color-neutral-200);
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-wrap: break-word;
    gap: 0.5rem;
    // letter-spacing: 0.235px; // To prevent bold-shifting
  }

  &-quickmenu {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    // visibility: hidden;
    max-width: 5.25rem;
    height: 2rem;
    padding: 0 0.5rem;
    gap: 0.25rem;
    z-index: 1;
    left: var(--ac-pixel-size-6);
    transform: translateX(-100%);
    background-color: var(--ac-color-neutral-white);
    outline: var(--ac-pixel-size-1) solid var(--ac-color-neutral-300);
    border-radius: var(--ac-pixel-size-9) 0 0 var(--ac-pixel-size-9);
    opacity: 0;
    transition: opacity 0.2s ease;

    &-button {
      padding: 4px;
    }
  }

  &:hover {
    .ac-template-product-item-content > [class^='ac-typography'] {
      // transform: translateX(3px);
    }
    .ac-template-product-item-quickmenu {
      visibility: visible;
      opacity: 1;
      // transform: translateX(-100%);
    }
  }

  &-numbers {
    position: relative;
    display: flex;
    max-width: 6.25rem;
    min-width: 6.25rem;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    color: var(--ac-color-neutral-700);

    [class^='ac-counter-input'] {
      color: var(--ac-color-neutral-900);
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    gap: 0.5rem;
    max-height: 0;
    transition: max-height 0.3s ease, visibility 0.3 ease;
  }

  &--expanded,
  &--locked {
    padding-top: 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  &--expanded {
    background-color: var(--ac-color-primary-50);
    margin: 0 -0.25rem;
    padding-bottom: 0.75rem;

    .ac-template-product-item-actions {
      visibility: visible;
      max-height: 400px;
    }
  }

  &--locked {
    margin: 0 -0.25rem;
    background-color: var(--ac-color-neutral-200);
  }

  &--read-only {
    pointer-events: none;
  }
}

.ac-template-product-list {
  background-color: var(--ac-color-neutral-white);
  padding: 0 0.5rem;
  border-radius: var(--ac-pixel-size-12);
  max-width: 18.125rem;
}
