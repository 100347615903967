@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-typography {
  overflow: hidden;
  text-overflow: ellipsis;
  @include body-font;

  &--md {
    font-size: var(--ac-typography-font-size-16);
    line-height: var(--ac-typography-line-height-24);
  }

  &--sm {
    font-size: var(--ac-typography-font-size-14);
    line-height: var(--ac-typography-line-height-20);
  }

  &--xs {
    font-size: var(--ac-typography-font-size-12);
    line-height: var(--ac-typography-line-height-16);
  }

  &--semibold {
    font-weight: var(--ac-typography-font-weight-semibold);
  }

  &--regular {
    font-weight: var(--ac-typography-font-weight-regular);
  }

  &--light {
    font-weight: var(--ac-typography-font-weight-light);
  }

  &--color-neutral-500 {
    color: var(--ac-color-neutral-500);
  }

  &--color-neutral-700 {
    color: var(--ac-color-neutral-700);
  }
  &--color-neutral-900 {
    color: var(--ac-color-neutral-900);
  }
}
