@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-margin {
    background-color: var(--ac-color-secondary-300);
    display: flex;
    padding: var(--ac-pixel-size-8);
    border-radius: var(--ac-pixel-size-9);
    align-items: center;
    
    &-info {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0 var(--ac-pixel-size-8);

        &-amount {
           white-space: nowrap;
        }

        &-icon {
            fill: var(--ac-color-neutral-700);
            margin: 0 var(--ac-pixel-size-4);
        }

    }
}