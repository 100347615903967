@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 21.125rem;
  transition: transform 0.25s ease;
  transform: translateX(100%);
  background-color: var(--ac-color-neutral-white);
  box-shadow: -8px 0px 36px rgba(0, 0, 0, 0.1);
  z-index: 5;
  height: 100%;
  overflow-y: auto;

  &--open {
    transform: translateX(0);
  }
}
