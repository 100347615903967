@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-divider {
  height: var(--ac-pixel-size-1);
  border: none;
  margin: 0;

  &--color-neutral-200 {
    background-color: var(--ac-color-neutral-200);
  }
  &--color-neutral-300 {
    background-color: var(--ac-color-neutral-300);
  }
}
