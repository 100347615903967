@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.template-confirm-match {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;

    text-align: center;
  }

  &__actions {
    display: flex;
    gap: 1rem;
    padding-top: 2.5rem;
  }
}
