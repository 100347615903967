@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-tags-create-modal-form {
  &--intro {
    padding: var(--ac-pixel-size-12) 0 var(--ac-pixel-size-24) 0;
  }

  &--inputs {
    display: flex;
    flex-direction: column;
    gap: var(--ac-pixel-size-16);
  }

  &--actions {
    margin-top: var(--ac-pixel-size-40);
    display: flex;
    flex-direction: row;
    gap: var(--ac-pixel-size-16);
    justify-content: flex-end;
  }
}
