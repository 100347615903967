@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.template-salad-menu-edit {
  position: relative;

  &-more {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.5rem 0;
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding-top: 1rem;
  }
}
