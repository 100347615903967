@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-avatar {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  text-indent: 100%;
  overflow: hidden;
  z-index: 1;
  transition: all 0.25s ease;

  &--error::after,
  &:not([src])::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ac-color-neutral-400);
    text-indent: 0;
    text-transform: uppercase;
  }
}
