@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-profile-list-item {
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1.5rem 1fr;
  grid-template-rows: 1.5rem 1fr;
  gap: 0.75rem 0.5rem;
  grid-template-areas:
    ". ."
    "content content";
  align-items: center;
  padding: 0.75rem 0;
  border-top: var(--ac-pixel-size-1) solid var(--ac-color-neutral-200);

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 0;
    grid-area: content;
  }
}
