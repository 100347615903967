@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-counter-input {
  width: 2.5rem;
  height: 2rem;
  justify-content: center;

  input {
    text-align: center;
    font-size: var(--ac-pixel-size-12);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  [class^="ac-typography"] {
    font-weight: var(--ac-typography-font-weight-regular);
    font-size: var(--ac-pixel-size-12);
  }
}
