@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-alert {
  max-height: 0;
  height: auto;
  display: flex;
  align-items: center;
  gap: var(--ac-pixel-size-14);
  opacity: 0;
  visibility: hidden;
  font-size: var(--ac-typography-font-size-14);
  transition: transform, max-height 0.2s, visibility, padding, opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transform-origin: bottom;
  transform: perspective(600px) rotateX(90deg);

  &--visible {
    opacity: 1;
    transform: perspective(600px) rotateX(0deg);
    max-height: 5rem;
    visibility: visible;
  }

  &--size-sm {
    font-size: var(--ac-pixel-size-12);
    height: 2.5rem;
  }
}
