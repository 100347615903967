@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-bibi-info {
  &-title-wrapper {
    display: flex;
    flex-direction: row;
    gap: var(--ac-pixel-size-8);
    align-items: center;
  }

  &-line {
    display: flex;
    flex-direction: row;
    gap: var(--ac-pixel-size-8);
    align-items: center;

    &-dot {
      display: inline-block;
      width: var(--ac-pixel-size-14);
      height: var(--ac-pixel-size-14);
      border-radius: 50%;
      margin-left: var(--ac-pixel-size-5);
    }
  }
}
