@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-form {
  &-container {
    padding: 1.5rem 0 0;
  }

  &--layout-masonry,
  &--layout-column {
    column-gap: 1.5rem;

    @media #{$breakpoint-xl-up} {
      column-count: 2;
    }

    column-count: 1;

    & > * {
      break-inside: avoid-column;
      margin-bottom: 1.5rem;
    }
  }
  &--layout-column {
    column-count: 1;
  }
}
