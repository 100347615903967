@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-supplier-delivery-times {
  background-color: white;
  position: relative;
  margin: 0 -1.5rem;
  margin-bottom: var(--ac-pixel-size-6);
  max-width: 46rem;
  min-width: 20rem;

  header {
    margin-top: -1px;
    padding-top: 2.5rem;
    padding-bottom: 0;
    height: unset;

    & > :first-child {
      margin-top: -2.5rem;
      padding-bottom: 1.875rem;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }

  // Floating top-horizontal label
  & > &-label {
    position: absolute;
    left: 7.5rem;
    right: 0;
    text-align: center;
  }

  // Horizontal + vertical label
  & &-label[class^="ac-typography"] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: var(--ac-color-neutral-500);
  }

  &-row {
    display: grid;
    grid-template-columns: min(25%, 8rem) repeat(5, 15%);
    gap: 0;
    padding: 1rem 0;
    text-align: center;
    border-top: var(--ac-pixel-size-1) solid var(--ac-color-neutral-200);
    height: 1rem;

    [class^="ac-typography"] {
      color: var(--ac-color-neutral-700);
    }

    div[role="checkbox"] {
      width: 1.25rem;
      justify-self: center;
    }

    & > :first-child {
      margin: -1rem 0;
      padding: 1rem 0;
      background-color: var(--ac-color-neutral-50);
      border-right: var(--ac-pixel-size-1) solid var(--ac-color-neutral-200);
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
