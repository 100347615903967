@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-profile-list-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--ac-color-neutral-900);
  fill: var(--ac-color-primary-500);
  text-decoration: none;
  border-top: var(--ac-pixel-size-1) solid var(--ac-color-neutral-200);

  &--disabled {
    pointer-events: none;
    color: var(--ac-color-neutral-400);
    fill: var(--ac-color-neutral-400);
  }
}
