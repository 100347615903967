@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-drop-target-container {
  position: absolute;
  border-radius: var(--ac-pixel-size-12);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: use-alpha('--ac-color-secondary-300', 0.9);
  border: 3px dashed var(--ac-color-secondary-700);
  pointer-events: none;
  transition: opacity 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;

  &--can-drop {
    opacity: 0.85;
  }

  &--is-over {
    opacity: 1;
  }
}
