@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-datagrid-filters {
  width: 100%;
  gap: 1rem;
  display: inline-flex;
  padding: 1.5rem 1.5rem 1rem 1rem;

  &--text {
    margin-right: auto;
    min-width: 16rem;
  }

  &--select {
  }

  &--boolean {
    font-size: var(--ac-typography-font-size-14);
    color: var(--ac-color-neutral-500);
    display: flex;

    &__label {
      display: inline-block;
      vertical-align: middle;
      padding-top: var(--ac-typography-line-height-16);
      margin-right: .5rem;
    }
  }
}
