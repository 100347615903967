@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-category {
  transition: box-shadow 0.2s ease;
  &-title {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;

    &-actions {
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      transition: opacity 0.2s ease;
    }

    &:hover {
      .ac-template-category-title-actions {
        opacity: 1;
      }
    }
  }

  &:hover {
    box-shadow: 0px 1px 6px rgba(32, 33, 36, 0.28),
      0px 3px 12px rgba(189, 197, 209, 0.2);
  }
}
