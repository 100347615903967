html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  @include body-font();
}

#app {
  background-color: var(--ac-app-background-color);
  overflow: hidden;
  background-image: url(~@assets/images/bb-bg-vector.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
}

#app > main {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
