@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-todo-occupancy {
  &-table {
    &-wrapper {
      border: 1px solid var(--ac-color-secondary-100);
      border-radius: 9px;
      padding: var(--ac-pixel-size-11);
    }

    &-header {
      display: inline-block;
      text-align: center;
      width: 2rem;
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--ac-typography-font-size-12);
      text-align: center;
      border: 1px solid var(--ac-color-neutral-200);
      border-radius: var(--ac-pixel-size-7);
      line-height: 2rem;
      width: 2rem;
      margin: 0;
    }
  }

  &-button {
    &-wrapper {
        margin-top: 1rem;
      gap: 1rem;
      display: flex;
      justify-content: flex-end;
    }
  }
}
