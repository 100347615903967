@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-profile-header {
  padding: 1rem 0;

  &__account {
    display: flex;
    gap: 1rem;
    padding: 0.75rem;

    border-radius: var(--ac-pixel-size-9);
    background-color: var(--ac-color-secondary-50);

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    img {
      height: 3rem;
      width: 3rem;
    }
  }

  &__updated {
    display: flex;
    flex-direction: column;
  }
}
