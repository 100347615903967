@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-container {
  transition: padding 0.25s ease;

  &--lg {
    padding: 3.25rem;
  }
  &--md {
    padding: 2.5rem;
  }
  &--sm {
    padding: 1.5rem;
  }
}
