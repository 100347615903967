@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-chip {
    border-radius: var(--ac-pixel-size-9);
    padding: var(--ac-pixel-size-6) var(--ac-pixel-size-12);
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    @include button-transitions;

    &--has-icon {
        padding: var(--ac-pixel-size-6) var(--ac-pixel-size-12) var(--ac-pixel-size-6) var(--ac-pixel-size-8);
    }

    &-icon {
        margin-right: var(--ac-pixel-size-2);
    }

    &--default {
        background-color: use-alpha("--ac-color-neutral-700", 0.24);
        color: var(--ac-color-neutral-700);

        .ac-chip-icon {
            fill: var(--ac-color-neutral-700);
        }
    }

    &--success {
        background-color: use-alpha("--ac-color-secondary-100", 0.12);
        color: var(--ac-color-secondary-500);

        .ac-chip-icon {
            fill: var(--ac-color-secondary-500);
        }
    }

    &--error {
        background-color: use-alpha("--ac-color-feedback-error", 0.12);
        color: var(--ac-color-feedback-error);

        .ac-chip-icon {
            fill: var(--ac-color-feedback-error);
        }
    }

    &--pill {
        border-radius: 16rem;
    }

    &--outlined {
        background-color: var(--ac-color-neutral-white);
        border: var(--ac-pixel-size-1) solid var(--ac-color-neutral-300);
    }

    &--badge {
        &::before {
            content: "";
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 100%;
            margin-right: 0.5rem;
            background-color: var(--badge-color);
        }
    }
}
