@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-salad-menu {
  &-button {
    &-wrapper {
      margin-top: 1rem;
      gap: 1rem;
      display: flex;
      justify-content: flex-end;
      min-height: 2.5rem;
    }
  }
}
