@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-accordion-item-icon {
    box-sizing: border-box;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: 1px solid;
  border-color: var(--ac-color-neutral-300);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--ac-color-neutral-300);
  transition: all 0.2s ease;

  &--DONE {
    background: var(--ac-color-feedback-succes);
    border-color: var(--ac-color-feedback-succes);
    fill: var(--ac-color-neutral-white);
  }

  &--WARNING {
    background: var(--ac-color-feedback-warning);
    border-color: var(--ac-color-feedback-warning);
    fill: var(--ac-color-neutral-white);
  }
}
