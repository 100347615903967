@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-match-rules {
  text-align: left;
  padding: 0;

  &__remark {
    padding: 0.5rem 0;
    fill: var(--ac-color-neutral-white);
    list-style: none;

    svg {
      float: left;
      margin-right: 0.5rem;
    }
  }
}
