@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-tooltip {
  padding: var(--ac-pixel-size-8);
  z-index: 999;
  border-radius: var(--ac-pixel-size-7);
  background-color: var(--ac-color-neutral-900);
  color: var(--ac-color-neutral-white);
  @include title-font();
  @include font-size(var(--ac-pixel-size-12));
}
