@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-datagrid-row {
    display: flex;
    flex-flow: row wrap;
    border-top: 1px solid use-alpha('--ac-color-secondary-100', .12);
    align-items: center;

    &--clickable {
        cursor: pointer;

        &:hover {
            background-color: var(--ac-color-neutral-50);
        }
    }

    &:first-of-type {
        border-top: initial;
    }
}