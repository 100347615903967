@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-claim-menu {
  display: flex;
  flex-direction: column;
  background-color: var(--ac-color-neutral-white);
  padding: 0.5rem;
  border-radius: var(--ac-pixel-size-9);
  gap: 0.5rem;
  box-shadow: 0px 0px 12px 0px rgba(13, 51, 56, 0.12),
    0px 8px 32px -4px rgba(13, 51, 56, 0.12);

  &-avatar-select {
    width: 2rem;
    margin-right: 0.44rem;
    height: 2rem;
  }

  &-button {
    padding: 0.5rem 0.5rem 0.5rem 0.75rem;
    box-sizing: border-box;
  }

  &-inner {
    border-radius: var(--ac-pixel-size-9);
    display: grid;
    grid-template-columns: 3rem 1fr;
    padding: 0.75rem;
    gap: 0.5rem;
    background-color: var(--ac-color-secondary-50);
    align-items: center;
    width: 15.8rem;

    &-right {
      display: grid;
    }
  }
}
