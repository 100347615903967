@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-lunch-days-table {
  padding-bottom: 1rem;
  &__header {
    display: grid;
    gap: 1rem;
    border-bottom: var(--ac-pixel-size-1) solid var(--ac-color-neutral-200);
  }
}
