@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-image-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &__error {
    font-size: var(--ac-typography-font-size-12);
    color: var(--ac-color-feedback-error);
  }
}

.ac-image-input {
  min-height: 1rem;

  &-img {
    height: 2.875rem;
    width: 2.875rem;

    padding: 0.5rem;

    border: 1px solid rgb(218, 225, 225);
    border-radius: var(--ac-pixel-size-9);

    cursor: pointer;

    &--modal {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &__btn-close {
      position: fixed;
      left: 3.125rem;
      top: 3.125rem;

      [class^="ac-button"] {
        fill: var(--ac-color-neutral-white);
      }
    }
  }
}

.ac-image-input-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ac-image-input-edit-action {
  display: flex;
  align-items: center;

  button {
    margin-left: var(--ac-pixel-size-12);
  }
}

// Modal
.ac-image-edit-modal {
  background-color: #fff;
  border-radius: var(--ac-pixel-size-9);
  padding: var(--ac-pixel-size-32);
  min-width: calc(400px + var(--ac-pixel-size-24));

  &-header {
    display: flex;
    justify-content: space-between;

    & > span {
      margin: -0.75rem;
    }
  }
}

.ac-image-upload-title {
  padding-bottom: var(--ac-pixel-size-24);
}

.ac-image-upload {
  min-height: 224px;
  min-width: 100%;
  border: 1px dashed var(--ac-color-neutral-500);
  border-radius: var(--ac-pixel-size-9);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: all 0.35s ease;
  cursor: pointer;
  &__text {
    &__highlight {
      color: var(--ac-color-primary-500);
    }
  }

  &:hover {
    .ac-image-upload__text__highlight {
      text-decoration: underline;
    }
  }

  &__dragging {
    background-color: rgb(246, 246, 246);
  }

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__text {
    margin-top: var(--ac-pixel-size-12);
    margin-bottom: var(--ac-pixel-size-4);
    font-size: var(--ac-pixel-size-16);
    font-weight: 600;
    text-align: center;
  }

  &__helperText {
    font-size: var(--ac-pixel-size-14);
    font-weight: 300;
    text-align: center;
    color: var(--ac-color-neutral-500);
  }
}

.drag_element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: var(--ac-pixel-size-0);
  right: var(--ac-pixel-size-0);
  bottom: var(--ac-pixel-size-0);
  left: var(--ac-pixel-size-0);
}

.ac-image-crop {
  width: 100%;
  border-radius: var(--ac-pixel-size-9);
  img {
    border-radius: var(--ac-pixel-size-9);
  }
}

.ac-image-crop-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--ac-pixel-size-24);
  button {
    margin-left: var(--ac-pixel-size-12);
  }
}
