@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-progress-trapezoid {
  --trapezium-width: 19.75rem;
  --trapezium-height: 85.7px;

  -webkit-appearance: none;
  appearance: none;
  all: unset;
  display: block;
  clip-path: path("M0 64c0-4 3-8 7-9L305 0c6-1 11 3 11 9v68c0 5-4 9-9 9H9c-5 0-9-4-9-9z");
  height: var(--trapezium-height);
  width: var(--trapezium-width);
  overflow: hidden;

  &::-webkit-progress-bar {
    background-color: var(--ac-color-secondary-50);
  }

  &::-webkit-progress-value {
    transition: width 1.5s cubic-bezier(0.22, 1, 0.36, 1);
    background-image: -webkit-linear-gradient(
      0deg,
      var(--ac-color-feedback-error) 0%,
      var(--ac-color-primary-500) 64%,
      var(--ac-color-secondary-500) 100%
    );
    background-size: var(--trapezium-width);
  }
}
