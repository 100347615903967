@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-profile-tab-tags-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1.5rem;
  height: 3.375rem;
  padding: 0.5rem 0;

  &--read-only {
    height: initial;
    grid-template-columns: 1fr;
  }

  &-checked-value {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 0;
    border-bottom: var(--ac-pixel-size-1) solid var(--ac-color-neutral-200);
  }
}
