@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-day-header {
  min-width: 323px;
  max-width: 450px;
  width: 100%;

  &-padding-8 {
    padding: 0 0.5rem;
  }

  &-card {
    background-color: var(--ac-color-secondary-300);
    padding: 0.5rem;
    border-radius: var(--ac-pixel-size-12) var(--ac-pixel-size-12) 0 0;
    position: relative;

    &-header {
      display: grid;
      grid-template-columns: 1fr min-content;
      align-items: center;
      padding: 0.5rem 0 0.5rem 0.5rem;

      &-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
