@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-checkbox {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  border-color: var(--ac-color-neutral-500);

  &-input {
    cursor: pointer;
    position: relative;
    margin: 0;
    top: 0;
    left: 0;
    appearance: none;
    min-width: var(--ac-pixel-size-20);
    width: var(--ac-pixel-size-20);
    height: var(--ac-pixel-size-20);
    border: 1px solid;
    border-radius: var(--ac-pixel-size-3);
    transition: border-color 0.2s ease;

    &-icon {
      position: absolute;
      margin-bottom: var(--ac-pixel-size-2);
      fill: var(--ac-color-primary-500);
      height: var(--ac-typography-line-height-20);
      opacity: 0;
      transform: scale(0.5);
      transition: opacity 0.25s ease, transform 0.25s ease;

      .ac-checkbox--checked &,
      .ac-checkbox--indeterminant & {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &-label {
    display: inline-block;
    margin-left: var(--ac-pixel-size-14);
  }

  &--checked,
  &--indeterminant {
    .ac-checkbox-input {
      border-color: var(--ac-color-primary-500);
    }
  }

  &--disabled {
    cursor: initial;
    pointer-events: none;
    .ac-checkbox-input {
      border-color: var(--ac-color-neutral-700);
    }
  }

  &:hover {
    .ac-checkbox-input {
      border-color: var(--ac-color-primary-700);
    }
  }

  &:focus {
    outline: solid use-alpha('--ac-color-primary-500', 0.24);
    border-color: var(--ac-color-primary-700);
  }
}
