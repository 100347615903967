@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

:root {
  // --toastify-color-light: #fff;
  --toastify-color-dark: var(--ac-color-neutral-900);
  // --toastify-color-info: #3498db;
  // --toastify-color-success: #07bc0c;
  // --toastify-color-warning: #f1c40f;
  // --toastify-color-error: #e74c3c;
  // --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  // --toastify-icon-color-info: var(--toastify-color-info);
  // --toastify-icon-color-success: var(--toastify-color-success);
  // --toastify-icon-color-warning: var(--toastify-color-warning);
  // --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: unset;
  // --toastify-toast-background: #fff;
  // --toastify-toast-min-height: 64px;
  // --toastify-toast-max-height: 800px;
  --toastify-font-family: 'Lexend', sans-serif;
  // --toastify-z-index: 9999;
  // --toastify-text-color-light: #757575;
  --toastify-text-color-dark: var(--ac-color-neutral-white);
  // --toastify-text-color-info: #fff;
  // --toastify-text-color-success: #fff;
  // --toastify-text-color-warning: #fff;
  // --toastify-text-color-error: #fff;
  // --toastify-spinner-color: #616161;
  // --toastify-spinner-color-empty-area: #e0e0e0;
  // --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  // --toastify-color-progress-info: var(--toastify-color-info);
  // --toastify-color-progress-success: var(--toastify-color-success);
  // --toastify-color-progress-warning: var(--toastify-color-warning);
  // --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  opacity: 0.92;
}

.Toastify__toast {
  border-radius: var(--ac-pixel-size-7);
  padding: 0.875rem 1.5rem 0.875rem 1.125rem;

  &-icon {
    fill: currentColor;
  }
}
