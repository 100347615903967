@import "../../styles/globals/breakpoints";
@import "../../styles/globals/mixins";
@import "../../styles/globals/variables";
@import "../../styles/globals/grid";

.ac-template-detail {
  &-day-container,
  &-day-headers {
    display: flex;
    flex-direction: row;
    gap: var(--ac-pixel-size-12);
    justify-content: stretch;

    &:after {
      min-width: 0;
      transition: min-width 0.25s ease;
      content: "";
    }

    &--sidebar-open {
      &:after {
        min-width: 21.125rem;
      }
    }

    & > :nth-last-child(1) {
      margin-right: -1rem;
    }
  }

  &-day-headers {
    position: sticky;
    top: 5rem;
    z-index: 2;
    margin-bottom: 0.5rem;
    background-color: var(--ac-app-background-color);
  }
}
