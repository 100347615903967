@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

option {
  font-weight: var(--ac-typography-font-weight-light);
}

.ac-select {
  display: inline-block;
  min-width: 10rem; //prevent overflowing on detailpages
  cursor: pointer;
  position: relative;
  > div {
    line-height: var(--ac-typography-line-height-12) !important;
  }

  &-placeholder {
    color: var(--ac-color-neutral-500);
  }

  &-icon {
    transform: rotate(0deg);
    transition: transform 0.2s ease;

    &--opened {
      transform: rotate(180deg);
    }
  }

  &-label {
    width: 100%;

    &-multi {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }
  }

  &-input {
    @include body-font();
    border: none;
    width: 100%;
    padding: 0;
    font-weight: var(--ac-typography-font-weight-light);
    font-size: var(--ac-typography-font-size-14);
    color: var(--ac-color-neutral-900);
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--ac-color-neutral-500);
    }
  }

  &-options {
    &-wrapper {
      position: absolute;
      max-height: 350px;
      overflow-y: auto;
      z-index: 5;
      margin-top: 0.5rem;
      width: 100%;
      display: grid;
    }

    &-option {
      position: relative;
      cursor: pointer;
      border-radius: var(--ac-pixel-size-9);

      &--default {
        padding: var(--ac-pixel-size-14);
      }

      &--multi {
        display: flex;
        gap: var(--ac-pixel-size-8);
      }

      &:hover,
      &:focus {
        background-color: use-alpha('--ac-color-primary-500', 0.08);
      }
    }
  }

  &--read-only {
    cursor: unset;
  }

  &--has-hint {
    position: relative;
    .ac-select-options-wrapper {
      margin-top: -0.5rem;
    }
  }

  &--no-min-width {
    min-width: 0;
  }
}
