@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-profile-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__day-row {
    display: flex;
    gap: 1rem;
  }

  &__links {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
