@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-app-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 16rem;
  min-width: 16rem;
  padding: 1rem;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.25s ease;

  &:hover {
    .ac-app-menu-densinator {
      opacity: 1;
    }
  }

  &--dense {
    width: auto;
    min-width: 2.5rem;
    max-width: 5rem;
    padding: 1rem 0.625rem;

    &:hover {
      .ac-app-menu-logo img {
        opacity: 0;
        transform: scale(0);
      }
    }

    .ac-app-menu-densinator {
      right: 50%;
      transform: translateY(-50%) translateX(50%);
    }

    .ac-app-menu-logo {
      max-width: 2.5rem;
      margin: 0 auto;
    }

    .ac-app-menu-search {
      max-width: 2.75rem;

      input {
        opacity: 0;
      }

      [class^='ac-input-base-adornment-start'] {
        margin: 0;
      }
    }
    .ac-app-menu-list {
      max-width: 2.5rem;

      &-item {
        &-icon {
          margin-right: 0;
        }
        &-text {
          max-width: 0%;
          opacity: 0;
        }
      }
    }
    .ac-app-menu-profile {
      position: relative;
      padding: 1rem 0;
      margin-left: 2px;

      & > span {
        position: absolute;
        margin-left: unset;
        left: 0;
      }

      &-name {
        display: none;
      }
    }
  }

  &--dense .ac-app-menu-profile:hover {
    .ac-app-menu-profile-img {
      pointer-events: none;
      opacity: 0;
      transform: scale(0);
    }
  }

  &-logo {
    padding: 0.5rem 1.5rem 1.5rem 0;
    position: relative;
    display: flex;

    & img {
      transform: scale(1);
      transition: all 0.25s ease;
      margin: initial;
    }
  }

  &-densinator {
    position: absolute;
    right: 0;
    margin-top: -0.5rem;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transition: opacity 0.25s ease;

    &:hover,
    &:focus {
      background-color: #fff;
      fill: var(--ac-color-neutral-700);
    }
  }

  &-search {
    transition: max-width 0.25s ease;
    justify-content: center;
    min-height: 2.5rem;
    height: 2.5rem;
    max-width: 14rem;
    width: 100%;
    outline: none;

    [class^='ac-input-base-adornment-start'] {
      transition: margin 0.25s ease;
      pointer-events: none;
    }

    &,
    & input {
      cursor: pointer;
    }
  }

  &-list {
    padding-left: 0;
    position: relative;
    min-width: 2.75rem;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 44px;
      position: absolute;
      top: 0;
      z-index: 0;

      border-radius: var(--ac-pixel-size-9);
      transform: translateY(
        calc(2.75rem * var(--ac-app-menu-list-selector--offset, 0))
      );
      background-color: var(--ac-color-secondary-500);
      transition: transform 0.2s cubic-bezier(0.22, 1, 0.36, 1);
    }

    &-item {
      position: relative;
      z-index: 1;
      list-style-type: none;
      color: var(--ac-color-neutral-700);
      border-radius: var(--ac-pixel-size-9);
      transition: background-color ease 0.2s;

      a {
        display: flex;
        align-items: center;

        min-height: 1rem;
        padding: 0.75rem;
        border-radius: var(--ac-pixel-size-9);

        color: inherit;
        font-size: var(--ac-typography-font-size-14);
        text-decoration: none;
      }

      :global(a.active) {
        color: var(--ac-color-neutral-white);
        font-weight: var(--ac-typography-font-weight-semibold);
        transition: background-color ease 0.15s 0.15s, color ease 0.1s 0.1s;

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          margin: -0.75rem;
          border-radius: var(--ac-pixel-size-9);
          transition: background-color ease 0.15s 0.15s;
        }

        &:hover {
          color: var(--ac-color-neutral-700);
          background-color: var(--ac-app-background-color);

          &::before {
            background-color: var(--ac-color-secondary-100);
          }
        }
      }

      &:hover {
        background-color: var(--ac-color-secondary-100);
      }

      &-text {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        transition: max-width 0.25s ease;
      }

      &-icon {
        height: 1.25rem;
        width: 1.25rem;
        min-height: 1.25rem;
        min-width: 1.25rem;
        margin-right: 1rem;
        fill: currentColor;
      }
    }
  }

  &-profile {
    display: flex;
    gap: 0.75rem;
    padding: 1rem 0.5rem;
    margin-top: auto;
    align-items: center;
    min-height: 3.5rem;
    width: 13.5rem;

    [class^='ac-typography'] {
      white-space: nowrap;
    }

    & > span {
      margin-left: auto;
    }
  }
}
