@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-todo-tasks {
  display: grid;
  padding: 0.75rem 0;
  gap: 1.5rem;

  &-empty {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 3rem 1.75rem;
    &-title {
      text-align: center;
      color: var(--ac-color-neutral-500);
    }
    fill: var(--ac-color-neutral-500);
  }
}
