@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: var(--ac-pixel-size-48);
    margin-bottom: var(--ac-pixel-size-30);
    margin-top: 0;

    &__left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;
        margin-right: 0.75rem;
    }
    &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
}
