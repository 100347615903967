@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-datagrid-pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--ac-pixel-size-20) var(--ac-pixel-size-20);

  &--right {
    display: grid;
    align-items: center;
    grid-template-columns: 3rem 200px 3rem;

    &-numbers {
      display: flex;
      justify-content: center;
    }

    &-next {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-number {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 1.5rem;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    transition: color 0.25s ease;
    color: var(--ac-color-neutral-500);

    &-element {
      font-weight: var(--ac-typography-font-weight-semibold);
    }

    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: use-alpha('--ac-color-secondary-500', 0.12);
      transform: scale(0);
      transition: transform 0.25s ease;
    }

    &:hover,
    &--active {
      color: var(--ac-color-secondary-500);

      &:after {
        transform: scale(1);
      }
    }
  }

  &-dots {
    color: var(--ac-color-neutral-500);
    display: inline-block;
    width: 1.5rem;
    text-align: center;
  }
}
