@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-claim {
  &-content {
    padding: 0.75rem 0 2.5rem;
    display: grid;
    gap: 1.5rem;

    &-label {
      margin-bottom: 0.5rem;
      display: block;
    }
  }

  &-claimer {
    padding: 0.75rem;
    background-color: var(--ac-color-secondary-50);
    border-radius: var(--ac-pixel-size-9);
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    gap: 1rem;

    &-image {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
    }

    &-inner {
      display: grid;
    }
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  &-select {
    width: 100%;
    &-option {
      display: grid;
      align-items: center;
      width: 100%;
      grid-template-columns: 2rem 1fr;
      gap: 0.5rem;
      padding: 7px 0;

      img.ac-template-claim-select-option-image {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &-history {
    display: grid;
    gap: 0.62rem;
    margin-top: 1rem;
    &-claimer {
      display: grid;
    }
  }
}
