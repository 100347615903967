@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-datagrid-header {
  &-cell {
    padding: 1rem;
    @include body-font();

    &-checkbox {
      &-selection {
        display: flex;
        align-items: center;
        &-label {
          margin: 0 var(--ac-pixel-size-20);
        }
      }
    }

    &-actions {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      justify-content: flex-end;

      &-left {
        flex: 1 0 auto;
        justify-content: flex-start;
      }

      &-right {
        flex: 0 0 auto;
        justify-content: flex-end;
      }
    }

    &--sortable {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &-content {
      display: flex;
      align-items: center;
    }

    &-sort-icon {
      margin-left: var(--ac-pixel-size-8);
    }
  }
}
