@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-todo-comment {
  &-empty {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 3rem 1.75rem;
    &-title {
      text-align: center;
      color: var(--ac-color-neutral-500);
    }
    fill: var(--ac-color-neutral-500);
  }

  &-reply-content {
    padding: 0;
    margin-bottom: 0;
    white-space: pre-wrap;
  }

  &-content {
    padding: 0.75rem;
    white-space: pre-wrap;
    border: 1px solid var(--ac-color-secondary-100);
    border-radius: var(--ac-pixel-size-9);
  }

  &-reply {
    border: 1px solid var(--ac-color-secondary-300);
    border-radius: var(--ac-pixel-size-9);
    padding: 0.75rem;

    &-heading {
      display: flex;
      gap: 0.5rem;

      &-meta {
        display: flex;
        flex-direction: column;
      }
    }

    &-avatar {
      height: 2rem;
      width: 2rem;

      &::after {
        font-size: var(--ac-pixel-size-12);
      }
    }

    &-input {
      outline: 1px solid var(--ac-color-secondary-100);
    }
  }

  &-button {
    &-wrapper {
      margin-top: 1rem;
      gap: 1rem;
      display: flex;
      justify-content: flex-end;
      min-height: 2.5rem;
    }
  }
}
