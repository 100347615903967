@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-form-card {
  padding-bottom: 0;

  &--overflow-auto {
    overflow: auto;
  }

  &--fit-content {
    height: fit-content;
  }

  // Add padding to title
  & > :first-child {
    padding-bottom: 1rem;
  }

  // Every form child except last
  & > *:not(:last-child) {
    border-bottom: var(--ac-pixel-size-1) solid var(--ac-color-neutral-200);
  }
}
