@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.template-sidebar-todo-tab {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 1rem;
  height: 100%;
  box-sizing: border-box;

  &-icon {
    &-wrapper {
      position: relative;
      width: 1.5rem;
      height: 1.5rem;
    }

    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.4s ease;
  }

  &-bottom {
    margin-top: auto;
  }
}
