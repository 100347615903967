@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

@keyframes shimmer {
  0% {
    background-position: -25rem 0;
  }
  100% {
    background-position: 125rem 0;
  }
}

.ac-input-base {
  position: relative;
  display: flex;
  align-items: center;
  border: var(--ac-pixel-size-1) solid var(--ac-color-transparent);
  border-radius: var(--ac-pixel-size-9);
  outline: var(--ac-pixel-size-1) solid var(--ac-color-neutral-300);
  box-sizing: border-box;
  transition: outline 0.2s ease, margin-bottom 0.1s ease, padding 0.2s ease;
  background-color: var(--ac-color-neutral-white);
  min-height: 2rem;

  &--had-adornment {
    padding: 0.625rem 0.875rem;
  }

  &-adornment {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: var(--ac-typography-line-height-20);

    &-start {
      margin-right: var(--ac-pixel-size-14);
    }

    &-end {
      margin-left: var(--ac-pixel-size-14);
    }
  }

  &-hint {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: var(--ac-pixel-size-8);
    overflow: hidden;
    transform: translateY(0%);
    transition: transform 0.2s ease, opacity 0.2s ease;
    font-size: var(--ac-typography-font-size-12);
    visibility: hidden;
    opacity: 0;
    color: var(--ac-color-neutral-500);

    &--visible {
      margin-bottom: 1.5rem;

      .ac-input-base-hint {
        visibility: visible;
        opacity: 1;
        transform: translateY(100%);
      }
    }
  }

  &:hover,
  &:focus-within {
    outline-color: var(--ac-color-primary-500);
  }

  &--disabled {
    outline-color: var(--ac-color-neutral-200);

    &:hover,
    &:focus-within {
      outline-color: var(--ac-color-neutral-200);
    }
  }

  &--error {
    outline-color: var(--ac-color-feedback-error);

    &:hover,
    &:focus-within {
      outline-color: var(--ac-color-feedback-error);
    }

    .ac-input-base-hint {
      color: var(--ac-color-feedback-error);
    }
  }

  &--without-borders {
    border: none;
    outline: none;
    background-color: var(--ac-color-transparent);
    padding: 0;
  }

  &::after {
    content: " ";
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    min-height: 1.125rem;
    position: absolute;
    left: 0;
    border-radius: var(--ac-pixel-size-9);
    background-image: linear-gradient(0, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%);
    background: var(--ac-color-secondary-50);
    background-image: linear-gradient(
      to right,
      var(--ac-color-secondary-50) 0%,
      var(--ac-color-neutral-white) 30%,
      var(--ac-color-secondary-50) 40%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    transition: visibility 0.2s, opacity 0.2s ease, background-image 0.2s, background-color 0.2s;

    animation: shimmer 1.5s forwards linear infinite;
  }
  &--loading::after {
    visibility: visible;
    opacity: 0.5;
  }
}
