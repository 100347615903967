@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-icon {
  display: inline-block;

  path {
    fill: inherit;
  }

  &--xs {
    width: 0.75rem;
  }

  &--sm {
    width: 1rem;
  }

  &--md {
    width: 1.25rem;
  }

  &--lg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--xl {
    width: 3rem;
  }
}
