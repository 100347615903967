@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-text-input {
  padding: 0;
  line-height: var(--ac-typography-line-height-20);
  width: 100%;
  box-sizing: content-box;

  border: none;
  outline: none;
  background: none;

  transition: padding-left ease 0.2s, font-weight ease 0.2s;
  font-size: var(--ac-typography-font-size-14);
  color: var(--ac-color-neutral-900);
  resize: vertical;

  &::placeholder {
    color: var(--ac-color-neutral-500);
  }

  @include body-font();
}
