@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ac-product-selector {
  &-button span {
    font-weight: var(--ac-typography-font-weight-regular);
  }

  &-popup {
    display: flex;
    flex-direction: column;
    max-height: 20.25rem;
    background-color: white;
    border-radius: var(--ac-pixel-size-9);
    box-shadow: var(--ac-box-shadow-md);
    z-index: 2;
    transition: height 0.2s ease;
    overflow: hidden;

    hr {
      flex-shrink: 0;
    }
  }

  &-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transition:
      opacity 0.4s ease,
      visible 0.4s ease;
    visibility: hidden;
    opacity: 0;

    &--loading {
      visibility: visible;
      opacity: 1;
    }
  }

  &-no-results {
    opacity: 0;
    animation: fadeIn 0.2s ease forwards;
    text-align: center;
  }

  &-results {
    min-height: 3.5rem;
    padding: 0.75rem;
    overflow: auto;
    position: relative;
  }

  &-products {
    margin-top: -0.75rem;
  }

  &-item {
    min-height: 3.5rem;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3.375rem;
    justify-content: space-between;
    padding: 0 0.75rem;
    margin: 0 -0.75rem;
    cursor: pointer;

    &:hover,
    &--selected {
      background-color: var(--ac-color-primary-50);
    }
  }

  &-price {
    text-align: right;
  }

  &-input {
    padding: 0.75rem;
    outline: none;
    border: none;
    flex-shrink: 0;

    svg {
      height: 20px;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
