@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-progress-circular {
  transform: rotate(-90deg);

  circle {
    fill: none;
    stroke-width: 12;
    stroke: var(--ac-color-neutral-300);
  }

  & > &-ring {
    transition: stroke-dashoffset 0.5s ease;
    stroke-dasharray: 100;
    stroke-dashoffset: calc(100 - var(--value));
    stroke-linecap: round;
    stroke: var(--ac-color-secondary-500);
  }
}
