@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-day {
  min-width: 323px;
  max-width: 450px;
  width: 100%;
  gap: 0.5rem;

  &-padding-8 {
    padding: 0 0.5rem;
  }

  &-card {
    background-color: var(--ac-color-secondary-300);
    padding: 0.5rem;
    border-radius: var(--ac-pixel-size-12);
    margin-bottom: 0.5rem;
    position: relative;

    &-header {
      display: grid;
      grid-template-columns: 1fr min-content;
      align-items: center;
      padding: 0.5rem 0 0.5rem 0.5rem;

      &-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;

    &-budget {
      &--error {
        color: var(--ac-color-feedback-error);
      }

      &--succes {
        color: var(--ac-color-feedback-succes);
      }
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &-lunch {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &-extra {
    &-categories {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
