:root {
  /* ---- Typography ---- */

  /* Font-size */
  --ac-typography-font-size-28: 1.75rem;
  --ac-typography-font-size-20: 1.25rem;
  --ac-typography-font-size-16: 1rem;
  --ac-typography-font-size-14: 0.875rem;
  --ac-typography-font-size-12: 0.75rem;

  /* Line-height */
  --ac-typography-line-height-36: 36px;
  --ac-typography-line-height-28: 28px;
  --ac-typography-line-height-24: 24px;
  --ac-typography-line-height-20: 20px;

  /* Font-weight */
  --ac-typography-font-weight-semibold: 600;
  --ac-typography-font-weight-regular: 400;
  --ac-typography-font-weight-light: 300;

  /* ---- Color ---- */

  /* Primary */
  --ac-color-primary-50: #fcebe3;
  --ac-color-primary-500: #f9834c;
  --ac-color-primary-700: #e05c1c;

  /* Secondary */
  --ac-color-secondary-50: #eaf4f3;
  --ac-color-secondary-100: #049f911f;
  --ac-color-secondary-500: #049f91;
  --ac-color-secondary-700: #008171;

  /* Feedback */
  --ac-color-feedback-succes: var(--ac-color-secondary-500);
  --ac-color-feedback-error: #e64e4e;

  /* Neutral */
  --ac-color-neutral-white: #ffffff;
  --ac-color-neutral-50: #f9fafa;
  --ac-color-neutral-200: #ebf0ef;
  --ac-color-neutral-300: #dae1e1;
  --ac-color-neutral-500: #94a8a6;
  --ac-color-neutral-700: #4d6663;
  --ac-color-neutral-900: #0d3338;

  /* Background */
  --ac-app-background-color: var(--ac-color-secondary-50);

  /* ---- Shadows ---- */

  --ac-box-shadow-xs: 1px 2px 2px rgba(0, 0, 0, 0.04);
  --ac-box-shadow-sm: 0px 8px 32px -4px rgba(13, 51, 56, 0.12), 0px 0px 12px rgba(13, 51, 56, 0.12);
  --ac-box-shadow-md: 0px 1px 6px rgba(32, 33, 36, 0.28);
  --ac-box-shadow-lg: 0px 0px 15px -2px rgba(0, 0, 0, 0.08), 0px 12px 38px 1px rgba(12, 45, 41, 0.48);
}
