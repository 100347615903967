@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-product-title {
  color: var(--ac-color-neutral-700);
  transition: color 0.2s ease;

  &--NEGATIVE {
    color: var(--ac-color-feedback-error);
  }
  &--LIMITED {
    color: var(--ac-color-feedback-warning);
  }
  &--POSITIVE {
    color: var(--ac-color-feedback-succes);
  }
  &--EXISTING {
    color: var(--ac-color-feedback-taken);
  }
  &--UNAVAILABLE {
    text-decoration: line-through;
  }
}
