@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-lunch-day-row {
  display: grid;
  gap: 1rem;
  align-items: center;
  min-height: 86px;
  margin-bottom: -16px;
}
