@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-sidebar {
  &-close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &-wrapper {
    padding: 1rem 1rem 0 1rem;
    height: 1px;
    min-height: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &-bottom-container {
    position: sticky;
    bottom: 0;
    padding: 1rem 0;
    background-color: var(--ac-color-neutral-white);
    display: flex;
    gap: 1rem;
    flex-direction: column;
    z-index: 2;
  }

  &-tabs {
    flex: 1 0 auto;
    height: 100%;
  }
}
