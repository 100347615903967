@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-title {
    @include title-font();
    margin: 0;
    display: block;
    color: var(--ac-color-neutral-900);

    &--1 {
        font-size: var(--ac-typography-font-size-28);
        line-height: var(--ac-typography-line-height-36);
    }

    &--2 {
        font-size: var(--ac-typography-font-size-20);
        line-height: var(--ac-typography-line-height-28);
    }

    &--3 {
        font-size: var(--ac-typography-font-size-16);
        line-height: var(--ac-typography-line-height-24);
    }
}
