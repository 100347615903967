@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-card {
  width: 100%;
  border-radius: var(--ac-pixel-size-12);
  background-color: var(--ac-color-neutral-white);
  box-sizing: border-box;
  border: 1px solid rgba(4, 159, 145, 0.12);

  &--padding-8 {
    padding: var(--ac-pixel-size-8);
  }
  &--padding-14 {
    padding: var(--ac-pixel-size-14);
  }
  &--padding-24 {
    padding: var(--ac-pixel-size-24);
  }
  &--padding-32 {
    padding: var(--ac-pixel-size-32);
  }
  &--elevation-xs {
    box-shadow: var(--ac-box-shadow-xs);
  }
  &--elevation-sm {
    box-shadow: var(--ac-box-shadow-sm);
  }
  &--elevation-lg {
    box-shadow: var(--ac-box-shadow-lg);
  }
  &--theme-info {
    border: var(--ac-pixel-size-1) solid use-alpha("--ac-color-secondary-100", 0.12);
    font-weight: var(--ac-typography-font-weight-semibold);
    color: var(--ac-color-secondary-500);
    fill: currentColor;
    background-color: var(--ac-color-secondary-50);
  }
  &--theme-error {
    border: var(--ac-pixel-size-1) solid use-alpha("--ac-color-feedback-error", 0.32);
    font-weight: var(--ac-typography-font-weight-semibold);
    color: var(--ac-color-feedback-error);
    fill: currentColor;
    background-color: var(--ac-background-feedback-error);
  }
}
