@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      height: 3rem;

      th {
        border-bottom: var(--ac-pixel-size-2) solid var(--ac-color-neutral-200);
        border-collapse: collapse;
        color: var(--ac-color-neutral-700);
        font-size: var(--ac-typography-font-size-12);
      }
    }

    text-align: left;
  }

  tbody {
    tr {
      height: 5rem;

      td {
        font-size: var(--ac-typography-font-size-14);
        padding-right: 1.5rem;

        &:last-child {
          padding-right: 0;
        }
      }

      &:not(:last-child) td {
        border-bottom: var(--ac-pixel-size-1) solid var(--ac-color-neutral-200);
      }
    }
  }
}
