@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

div.ac-select-chip {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  min-height: 3rem;
  gap: 0.75rem;
  border: none;
  outline: none;

  button {
    span {
      font-weight: var(--ac-typography-font-weight-regular);
    }

    &[class*='color-secondary'] {
      color: var(--ac-color-neutral-700);
    }
  }
}
