@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-todo-tasks-item {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: flex-start;
  transition: all 0.5s ease;
  color: var(--ac-color-neutral-900);

  &-author {
    display: flex;
    margin-top: 0.25rem;
    align-items: center;
  }

  &-repeat {
    margin-right: 0.25rem;

    span {
      display: flex;
    }

    &-icon {
      margin-right: 0.25rem;
    }
  }

  &--is-checked {
    color: var(--ac-color-neutral-500);
    fill: var(--ac-color-neutral-500);
  }

  &--loading {
    opacity: 0.2;
  }
}
