@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

@keyframes bg-in {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(12, 26, 23, 0.64);
  }
}

@keyframes bg-out {
  from {
    background: rgba(12, 26, 23, 0.64);
  }
  to {
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes scale-in {
  from {
    opacity: 0;
    transform: scale(0.75) translateY(2rem);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes scale-out {
  from {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
  to {
    opacity: 0;
    transform: scale(0.75) translateY(4rem);
  }
}

.ac-modal {
  background: none;
  border: none;
  outline: none;
  overflow: unset;
  width: 100%;
  height: 100%;

  color: var(--ac-color-neutral-700);

  &--width-md {
    max-width: 28rem;
  }
  &--width-lg {
    max-width: 30rem;
  }
  &--width-xl {
    max-width: 32rem;
  }
  &--width-full {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  &--animate-out {
    .ac-modal-wrapper {
      animation: scale-out 0.25s ease forwards;
    }
    &::backdrop {
      animation: bg-out 0.3s ease forwards !important;
    }
  }

  &-wrapper {
    animation: scale-in 0.25s cubic-bezier(0.76, 0, 0.24, 1) forwards;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &::backdrop {
    background: rgba(0, 0, 0, 0);
    animation: bg-in 0.25s ease forwards;
  }

  &-confirm {
    &-actions {
      padding-top: 1.625rem;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}
