@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-todo-extras-day-product {
  display: flex;
  justify-content: space-between;
  align-items: center;

  >span {
    min-width: 50px;
    text-align: right;
  }
}