@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

@keyframes pulse {
  50% {
    transform: scale(1.02);
  }
}

.ac-accordion {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  &-item {
    background-color: var(--ac-color-neutral-white);
    border: 1px solid var(--ac-color-neutral-200);
    border-radius: var(--ac-pixel-size-9);
    transition: max-height 0.25s cubic-bezier(0.87, 0, 0.13, 1);
    overflow: hidden;
    max-height: 52px;

    &[aria-expanded='true'] {
      max-height: 100%;
      transition: max-height 0.5s cubic-bezier(0.87, 0, 0.13, 1);
      animation: pulse 0.25s ease;

      .ac-accordion-item {
        &-header {
          color: var(--ac-color-neutral-900);
        }

        &-content-wrapper {
          opacity: 1;
          height: 100%;
          visibility: visible;
        }
      }
    }

    &-header {
      all: unset;
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      box-sizing: border-box;
      min-height: 3rem;
      padding: 0.75rem;
      width: 100%;
      z-index: 2;
      cursor: pointer;
      color: var(--ac-color-neutral-700);
      font-weight: var(--ac-typography-font-weight-semibold);
      font-size: var(--ac-pixel-size-14);
      transition: color 0.25s ease;
    }

    &-title {
      width: 100%;
    }

    &-arrow {
      fill: var(--ac-color-neutral-700);
      transition: transform 0.25s 0.15s ease;

      &--expanded {
        transform: rotate(-180deg);
      }
    }

    &-content-wrapper {
      transition: all 0.45s cubic-bezier(0.87, 0, 0.13, 1);
      opacity: 0;
      height: 100%;
      overflow: hidden;
      display: flex;
      visibility: collapse;
    }

    &-content {
      padding: 0 0.75rem 0.75rem;
      width: 100%;
    }
  }
}
