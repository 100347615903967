@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-radio-input {
  appearance: none;
  box-sizing: content-box;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  border: solid var(--ac-pixel-size-1) var(--ac-color-neutral-300);
  position: relative;
  border-radius: 100%;
  cursor: pointer;

  &:checked {
    border: solid var(--ac-pixel-size-1) var(--ac-color-primary-500);
    background-color: var(--ac-color-primary-500);
  }
  &:checked::before {
    content: "";
    width: 0.625rem;
    height: 0.625rem;
    background-color: var(--ac-color-neutral-white);
    position: absolute;
    top: var(--ac-pixel-size-5);
    left: var(--ac-pixel-size-5);
    border-radius: 100%;
  }

  &--read-only {
    cursor: default;
    border: solid var(--ac-pixel-size-1) var(--ac-color-transparent);

    &:checked {
      background-color: var(--ac-color-transparent);
      border: solid var(--ac-pixel-size-1) var(--ac-color-transparent);
    }
    &:checked::before {
      background-color: var(--ac-color-neutral-700);
    }
  }
}
