@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-lunchbudget {
  &-content {
    padding: 2.5rem 0;

    &-label {
      margin-bottom: 0.5rem;
      display: block;
    }
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
