@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-loader {
    display: flex;
    flex: 1 0 100%;
    justify-content: center;
    align-items: center;
    font-size: inherit;

    &-wrapper {
        display: flex;
        height: 100%;
    }

    &-dot {
        width: 1em;
        height: 1em;
        border-radius: 50%;
        animation: fade 1.2s linear infinite;
        margin: .5em;
        animation: fade .5s infinite ease-in-out;
        animation-direction: alternate;
        opacity: 0;

        &:nth-child(1) {
            .ac-loader--primary & {
                background-color: var(--ac-color-primary-300);
            }
            
            .ac-loader--secondary & {
                background-color: var(--ac-color-secondary-100);
            }
            animation-delay: 0;
        }

        &:nth-child(2) {
            .ac-loader--primary & {
                background-color: var(--ac-color-primary-500);
            }
            
            .ac-loader--secondary & {
                background-color: var(--ac-color-secondary-500);
            }
            animation-delay: .125s;
        }
        
        &:nth-child(3) {
            .ac-loader--primary & {
                background-color: var(--ac-color-primary-700);
            }
            
            .ac-loader--secondary & {
                background-color: var(--ac-color-secondary-700);
            }
            animation-delay: .25s;
        }
    }
}

@keyframes fade {
    0% {
        opacity:0;
    }
    100% {
        opacity: 1;
    }
}