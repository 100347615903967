@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-datagrid-cell {
    display: flex;
    // flex: 1 1 auto;
    padding: var(--ac-pixel-size-20) var(--ac-pixel-size-20);
    position: relative;
    white-space: nowrap;
    overflow: hidden;
}