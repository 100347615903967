@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-switch {
  position: relative;
  display: inline-block;
  cursor: pointer;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: var(--ac-pixel-size-12);
  }

  &--is-loading {
    pointer-events: none;
  }

  &--readonly {
    pointer-events: none;
    cursor: initial;
  }

  &--sm {
    width: var(--ac-pixel-size-38);
    height: var(--ac-pixel-size-24);
  }

  &--md {
    width: var(--ac-pixel-size-48);
    height: var(--ac-pixel-size-32);
  }

  &-checkbox {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &-slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--ac-color-neutral-500);
    border-radius: var(--ac-pixel-size-99);
    transition: background-color 0.3s ease;

    &-button {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      left: var(--ac-pixel-size-4);
      top: var(--ac-pixel-size-4);
      border-radius: 50%;
      background-color: var(--ac-color-neutral-white);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: left 0.2s ease;

      &-loader {
        font-size: var(--ac-pixel-size-2);
        padding: var(--ac-pixel-size-4);
      }

      &-icon {
        fill: var(--ac-color-neutral-500);
        transition: fill 0.2s ease;
      }

      .ac-switch--sm & {
        width: var(--ac-pixel-size-18);
        height: var(--ac-pixel-size-18);
        top: var(--ac-pixel-size-3);
        left: var(--ac-pixel-size-3);
      }
    }
  }

  &--checked {
    &.ac-switch--sm {
      .ac-switch-slider {
        background-color: var(--ac-color-secondary-500);
        &-button {
          left: var(--ac-pixel-size-16);
          &-icon {
            fill: var(--ac-color-secondary-500);
          }
        }
      }
    }

    &.ac-switch--md {
      .ac-switch-slider {
        background-color: var(--ac-color-secondary-500);

        &-button {
          left: var(--ac-pixel-size-20);
          &-icon {
            fill: var(--ac-color-secondary-500);
          }
        }
      }
    }
  }

  &--error {
      font-size: var(--ac-typography-font-size-12);
      color: var(--ac-color-feedback-error);
  }
}
