@import "../../styles/globals/breakpoints";
@import "../../styles/globals/mixins";
@import "../../styles/globals/variables";
@import "../../styles/globals/grid";

main.ac-default-layout {
  &--menu-dense {
    padding-left: 1.5rem;
  }
}
