@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-template-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  display: grid;
  grid-template-columns: minmax(2.6rem, 33.875rem) 1fr minmax(auto, 33.875rem);
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
  transition: margin-right 0.25s ease;

  &::after {
    content: "";
    position: absolute;
    background-color: var(--ac-app-background-color);
    top: -2.5rem;
    left: -4rem;
    right: -4rem;
    bottom: -1.625rem;
    z-index: -1;
  }

  &--sidebar-open {
    margin-right: 21rem;
  }

  &--left {
    display: flex;
    align-items: center;
  }

  &--center {
    display: flex;
    justify-content: center;
  }

  &--right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }

  &-title {
    margin-left: var(--ac-pixel-size-12);
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
}
