@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-global-search {
  &-input {
    border: none;
    outline: none;
    padding: 1.375rem;
  }

  &-divider {
    margin: 0;
    border: none;
    height: var(--ac-pixel-size-1);
    background-color: var(--ac-color-neutral-300);
  }

  &-empty-state {
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--ac-color-neutral-500);

    [class^='ac-title'] {
      margin-top: 1.5rem;
      color: var(--ac-color-neutral-500);
    }

    [class^='ac-typography'] {
      margin-top: 0.25rem;
    }

    [class^='ac-icon'] {
      height: 5rem;
      width: 5rem;
      fill: var(--ac-color-neutral-400);
    }
  }

  &-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > [class^='ac-tab-panel'] {
      overflow: auto;
    }
  }

  &-results {
    height: 36rem;

    &-category {
      border: none;
      padding: 0.5rem;

      legend {
        color: var(--ac-color-neutral-500);
        padding-top: 1rem;
        margin-bottom: -1.5rem;
        padding-left: 0.5rem;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: -0.5rem;

        li {
          min-height: 3.5rem;
          border-radius: var(--ac-pixel-size-9);
          color: var(--ac-color-neutral-900);

          cursor: pointer;

          &:hover {
            background-color: use-alpha('--ac-color-primary-500', 0.08);
            color: var(--ac-color-primary-500);
            fill: var(--ac-color-primary-500);
          }

          a {
            display: flex;
            gap: 1rem;
            padding: 1rem;
            color: inherit;
            text-decoration: none;

            .ac-global-search-results-title {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
