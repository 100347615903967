@import "../../../../styles/globals/breakpoints";
@import "../../../../styles/globals/mixins";
@import "../../../../styles/globals/variables";
@import "../../../../styles/globals/grid";

.ac-layered-menu-item {
  min-width: 6rem;
  padding: var(--ac-pixel-size-12);
  display: flex;
  gap: var(--ac-pixel-size-12);
  align-items: center;
  cursor: pointer;

  &-label {
    width: 100%;
  }

  &-icon {
    width: var(--ac-pixel-size-20);
  }

  &:hover {
    background-color: var(--ac-color-neutral-200);
  }
}
