@import "../../../styles/globals/breakpoints";
@import "../../../styles/globals/mixins";
@import "../../../styles/globals/variables";
@import "../../../styles/globals/grid";

.ac-form-input {
  display: grid;
  grid-template-columns: 1fr;

  @media #{$breakpoint-md-up} {
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  }

  align-items: center;
  padding: 1rem 0;

  & > label {
    color: var(--ac-color-neutral-700);
    font-size: var(--ac-typography-font-size-14);
    padding-bottom: 1rem;

    @media #{$breakpoint-md-up} {
      grid-template-columns: 0;
      padding-bottom: 0;
    }
  }
}
